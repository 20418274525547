import axios from "axios"
import { getItem } from "@/utils/storage"

axios.defaults.baseURL = "https://orient.smng.kg/api"
axios.defaults.crossDomain = true

axios.interceptors.request.use((config) => {
  const language = getItem("language")

  const { headers } = config
  headers["Accept-Language"] = language || "ru"

  return config
})
export default axios
