<template>
  <div class="header">
    <router-link :to="{ name: 'home' }"
      ><img
        src="@/assets/logo.png"
        class="header__logo"
        alt=""
      />
    </router-link>

    <router-link :to="{ name: 'cart' }" class="header__cart-link"
      ><span class="icon-shopping-cart"></span>

      <span>{{ $t("yourOrders") }}</span>
      <span v-if="cartCounter" :class="{ added: addedEffect }">
        ({{ cartCounter }})</span
      >
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "header",
  computed: {
    ...mapState(["cartItems", "addedEffect"]),
    cartCounter() {
      return this.cartItems
        ? this.cartItems.reduce((total, cart) => (total += cart.qty), 0)
        : 0;
    },
  },
  i18n: {
    messages: {
      ru: {
        yourOrders: "Ваши заказы",
      },
      en: {
        yourOrders: "Your Orders",
      },
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  background-color: #53284f;
  position: fixed;
  width: 100%;
  height: 80px;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 15%);
  z-index: 9;
  padding: 10px 20px;
  align-items: center;
}

.header__logo {
  width: 90px;
}

.header__cart-link {
  color: white;
  text-decoration: none;
  margin-left: 18px;
  position: relative;
}

.header__cart-link span.icon-shopping-cart {
  font-size: 20px;
  margin-right: 8px;
}

.added {
  color: #65a600;
  font-weight: bold;
}
</style>
