<template>
  <BasePopup @close="$emit('close', true)">
    <div class="items-popup">
      <div class="items-popup__title">{{ params.title }} <img src="@/assets/icons/close.svg" @click="$emit('close', true)"></div>
      <div v-if="isDataLoading">
        <b-spinner type="grow" variant="success"></b-spinner>
        <div>Загрузка данных</div>
      </div>
      <div v-else="!isDataLoading && items.length">
        <FoodMenuItem
          v-for="(item, index) in normalizedItems"
          :key="item.id"
          :item="item"
          :index="index"
          type="restaurant"
          @increaseQty="increaseQty"
          @discreaseQty="discreaseQty"
        />
      </div>
      <div class="close" @click="$emit('close', true)">Закрыть категорию</div>
    </div>
  </BasePopup>
</template>

<script>
import i18n from "@/i18n";
import BasePopup from '@/components/popups/BasePopup'
import views from "@/mixins/views";

export default {
  name: "ItemsPopup",
  mixins: [views],
  components: {
    BasePopup
  },
  props: {
    params: Object,
  },
  data() {
    return {
      number: 0,
      isDataLoading: false,
    };
  },
  i18n: {
    messages: {
      ru: {
        title: "Выберите номер вашей комнаты",
        free: "Бесплатно",
        set: "Установить",
      },
      en: {
        title: "Please insert your room number",
        free: "Free",
        set: "Save",
      },
    },
  },
  async created () {
    this.isDataLoading = true
    await this.$store.dispatch("getItemsByCategoryId", {type: this.params.type, id: this.params.id});
    this.isDataLoading = false
  },
};
</script>

<style lang="scss" scoped>
.items-popup {
  padding: 32px;
  background: white;
  border-radius: 16px;

  &__title {
    text-align: left;
    font-weight: bold;
    font-size: 17px;
    border-bottom: 1px solid #30363d47;
    padding-bottom: 10px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      cursor: pointer;
      width: 20px;
    }
  }
}

.close {
  cursor: pointer;
}
</style>
