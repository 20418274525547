<template>
  <div class="layout">
    <RoomPopup v-if="!roomNumber" />
    <ItemsPopup
      v-if="isMenuPopupOpened"
      @close="() => (isMenuPopupOpened = false)"
      :params="byCategory"
    />
    <Header class="layout__header" />
    <div class="layout__body"><router-view /></div>
    <Footer class="layout__footer" />
  </div>
</template>

<script>
import Header from "@/components/common/Header"
import Footer from "@/components/common/Footer"
import RoomPopup from "@/components/popups/RoomPopup"
import ItemsPopup from "@/components/popups/ItemsPopup"
import { mapState } from "vuex"
import { eventBus } from "@/main"

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
    ItemsPopup,
    RoomPopup,
  },
  data() {
    return {
      isMenuPopupOpened: false,
      byCategory: {
        id: null,
        type: null,
        title: null,
      },
      itemsPopupId: null,
      itemsPopupTitle: "",
      itemsPopupCategory: null,
    }
  },
  computed: {
    ...mapState(["roomNumber"]),
  },
  mounted() {
    eventBus.$on("menu", (data) => {
      this.isMenuPopupOpened = true
      this.byCategory = {
        id: data.id,
        type: data.type,
        title: data.title,
      }
    })
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";

.layout {
  min-height: 100vh;
  display: grid;
  grid-template: minmax(80px, auto) 1fr minmax(70px, auto) / 1fr;
  grid-template-areas:
    "header"
    "body"
    "footer";

  &__header {
    grid-area: header;
  }

  &__body {
    grid-area: body;
  }

  &__footer {
    grid-area: footer;
  }
}
</style>
