import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/restaurant-menu",
    name: "restaurant",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Restaurant.vue"),
  },
  {
    path: "/room-service-menu",
    name: "roomService",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RoomService.vue"),
  },
  {
    path: "/things",
    name: "things",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Things.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
  },
  {
    path: "/testimonial",
    name: "testimonial",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Testimonial.vue"),
  },
  {
    path: "/information",
    name: "information",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Information.vue"),
  },
  {
    path: "/travel",
    name: "travel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Travel.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
