import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "ru",
});

export { i18n as default };
