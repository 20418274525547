<template>

  <div class="home wrapper">

    <div class="container">

      <div class="home__title">{{ $t("welcomeTitle") }}</div>

      <div class="home__description"> {{ $t("welcomeText") }} </div>

      <div class="menu">

        <router-link
          :to="{ name: item.link }"
          class="menu__item"
          v-for="item in menuItems"
          :key="item.title"
        >

          <div class="menu__cover"></div>

          <img class="menu__image" :src="item.background" alt="" />

          <div class="menu__data">

            <span
              v-if="item.link !== 'travel'"
              class="menu__icon"
              :class="item.icon"
            ></span>

            <img
              v-if="item.link === 'travel'"
              style="    width: 50px;
    display: block;
    margin: auto;
    margin-bottom: 10px;"
              src="https://orient.smng.kg/uploads/luggage.png"
            />

            <span class="menu__title">{{ item.title }}</span>

          </div>

        </router-link>

      </div>

      <a
        href="https://wa.me/996706140160"
        target="_blank"
        class="home__whatsapp"
      >

        <span class="icon icon-whatsapp"></span>

        <span class="text">{{ $t("whatsappUs") }}</span>

      </a>

    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["serviceMenu"]),
    menuItems() {
      return [
        {
          title: this.$t("roomServiceMenu"),
          icon: "icon-coffee",
          background: "https://orient.smng.kg/uploads/food.png",
          link: "roomService",
        },
        {
          title: this.$t("restaurantMenu"),
          icon: "icon-tray",
          background: "https://orient.smng.kg/uploads/eda.jpeg",
          link: "restaurant",
        },
        {
          title: this.$t("requestItems"),
          icon: "icon-hang-clothes",
          background: "https://orient.smng.kg/uploads/polotence.jpeg",
          link: "things",
        },
        {
          title: this.$t("information"),
          icon: "icon-information",
          background:
            "https://orient.smng.kg/uploads/information-system-supporting-science.jpeg",
          link: "information",
        },
        {
          title: this.$t("giveFeedback"),
          icon: "icon-testimonial",
          background: "https://khantengri.kg/uploads/custmer-testimonials.jpeg",
          link: "testimonial",
        },
        {
          title: this.$t("travelWith"),
          icon: "icon-testimonial",
          background: "https://khantengri.kg/uploads/custmer-testimonials.jpeg",
          link: "travel",
        },
      ];
    },
  },
  i18n: {
    messages: {
      ru: {
        welcomeTitle: "Отель «Orient»",
        welcomeText:
          "Мы рады приветствовать Вас в отеле «Orient». Для вашего удобства мы собрали некоторую информацию, чтобы сделать ваше пребывание более комфортным.",
        whatsappUs: "Написать в Whatsapp",
        roomServiceMenu: "Меню Рум Сервис",
        restaurantMenu: "Меню Ресторана",
        requestItems: "Предметы по запросу",
        information: "Справочник для гостей",
        giveFeedback: "Мини опрос",
        map: "Развлечения",
        travelWith: "Путешествие вместе с «Orient Travel»",
      },
      en: {
        welcomeTitle: '"Orient" Hotel',
        welcomeText:
          'It is our pleasure to welcome you to "Orient". For your convenience, we have put some information together to make your stay more comfortable.',
        whatsappUs: "Whatsapp Us",
        roomServiceMenu: "Room Service Menu",
        restaurantMenu: "Restaurant Menu",
        requestItems: "Items Upon Request",
        information: "Guest Directory",
        giveFeedback: "Survey",
        map: "Entertainment",
        travelWith: "Travel with «Orient Travel»",
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  &__title {
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__description {
    font-family: "Raleway", sans-serif;
    margin-bottom: 20px;
  }

  &__whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: #65a600;
    padding: 10px;
    border-radius: 4px;
    margin-top: 12px;
    color: white;
    text-decoration: none;
    .icon {
      margin-right: 5px;
    }

    .text {
      font-family: "Raleway", sans-serif;
      font-weight: bold;
      font-size: 13px;
    }
  }
}
.menu {
  display: grid;
  grid-template-columns: repeat(2, minmax(125px, 1fr));
  gap: 10px;

  &__item {
    display: flex;
    align-items: center;
    background: #53284f;
    position: relative;
    height: 130px;
    text-decoration: none;
    padding: 10px;
    // box-shadow: 0 11px 16px rgb(165 165 165 / 21%);
    border-radius: 4px;
    overflow: hidden;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  &__data {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    z-index: 1;
    color: white;
  }

  &__title {
    font-size: 13px;

    font-family: "Raleway", sans-serif;
    font-weight: bold;
  }

  &__icon {
    font-size: 40px;
    margin-bottom: 10px;
  }

  &__cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000080;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
</style>

