import FoodMenuItem from "@/components/ui/FoodMenuItem";
import { mapState } from "vuex";
export default {
  data() {
    return {
      normalizedItems: null,
    };
  },
  components: {
    FoodMenuItem,
  },
  computed: {
    ...mapState(["items", "categories"]),
  },
  watch: {
    items() {
      this.normalizedItems = this.items.map((s) => ({ ...s, qty: 1 }));
    },
  },
  methods: {
    increaseQty(index) {
      this.normalizedItems[index].qty++;
    },
    discreaseQty(index) {
      if (this.normalizedItems[index].qty !== 1) {
        this.normalizedItems[index].qty--;
      }
    },
  },
};
