<template>
  <div id="app">
    <BaseLayout />
  </div>
</template>

<script>
import BaseLayout from "@/components/layout/Base";
import { getItem, setItem } from "@/utils/storage";
import i18n from "@/i18n";
export default {
  components: {
    BaseLayout,
  },
  async created() {
    const lang = getItem("language") || "ru";
    this.$store.dispatch("setLanguage", lang);
    i18n.locale = lang;
    if (this.$route.query.room_number) {
      this.$store.dispatch("setRoomNumber", this.$route.query.room_number);
    }

    if (this.$route.query.lang) {
      this.$store.dispatch("setLanguage", this.$route.query.lang);
      i18n.locale = this.$route.query.lang;
    }
    
    this.$store.dispatch("getCartItems");
    this.$store.dispatch("getRoomNumber");
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
