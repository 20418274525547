<template>
  <div class="popup" @click.self="$emit('close', true)">
    <div class="popup__content">
      <slot/>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "RoomPopup",
  data() {
    return {
      number: 0,
    };
  },
  i18n: {
    messages: {
      ru: {
        title: "Выберите номер вашей комнаты",
        free: "Бесплатно",
        set: "Установить",
      },
      en: {
        title: "Please insert your room number",
        free: "Free",
        set: "Save",
      },
    },
  },
  methods: {
    setRoomNumber() {
      if (this.number && this.number > 0) {
        this.$store.dispatch("setRoomNumber", this.number);
      }
    },
    lang(value) {
      this.$store.dispatch("setLanguage", value);
      this.$nextTick(() => {
        i18n.locale = value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  z-index: 99;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 110;
  overflow-y: auto;
  padding: 20px;
  background-color: rgba(11, 11, 11, 0.8);

  &__content {
    background: white;
    width: 85vw;
    min-width: 320px;
    max-width: 720px;
    border-radius: 10px;
    max-height: 90vh;
    // padding: 30px;
  }

  &__title {
    font-weight: bold;
  }

  &__input {
    margin-top: 25px;

    input {
      width: 80px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__btn {
    margin-top: 25px;
  }
}

.popup__langs {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  img {
    width: 35px;
  }
}

.popup__lang {
  margin: 0 8px;
}
</style>
