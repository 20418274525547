import Vue from "vue";
import Vuex from "vuex";
import axios from "@/plugins/axios";
import { getItem, setItem } from "@/utils/storage";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: null,
    categories: [],
    cartItems: [],
    roomNumber: null,
    currentLanguage: null,
    addedEffect: false,
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setCartItems(state, payload) {
      state.cartItems = payload;
    },
    setRoomNumber(state, payload) {
      state.roomNumber = payload;
    },
    setLanguage(state, payload) {
      state.currentLanguage = payload;
    },
    setAddedEffect(state, payload) {
      state.addedEffect = payload;
    },
  },
  actions: {
    updateAddedEffect({ commit }, payload) {
      commit("setAddedEffect", payload);
    },
    setLanguage({ commit }, payload) {
      commit("setLanguage", payload);
      setItem("language", payload);
    },
    async getItems({ commit }, type) {
      const items = await axios.get("/", {
        params: { type: type },
      });
      commit("setItems", items.data);
    },
    async getCategories({ commit }, category) {
      const items = await axios.get("/", {
        params: { category: category },
      });
      commit("setCategories", items.data);
    },
    async getItemsByCategoryId({ commit }, { id, type}) {
      const items = await axios.get("/", {
        params: { categoryId: id, categoryType: type },
      });
      commit("setItems", items.data);
    },
    async getCartItems({ commit }, type) {
      commit("setCartItems", getItem("cart") || []);
    },
    async addToCart({ state, commit }, payload) {
      const data = getItem("cart");

      if (data) {
        const item = data.find((i) => i.sign === payload.sign);

        // если в корзине есть, то обновляем кол-во и сабтотал
        if (item) {
          setItem(
            "cart",
            data.map((i) => {
              if (i.sign === item.sign) {
                return {
                  ...i,
                  qty: item.qty + payload.qty,
                  subtotal: (item.qty + payload.qty) * payload.price,
                };
              }

              return i;
            })
          );
        } else {
          // если элемент новый, добавляем к остальным

          data.push({ ...payload, subtotal: payload.qty * payload.price });
          setItem("cart", data);
        }
      } else {
        // если в корзине ничего нет, заносим первый элемент
        setItem(
          "cart",
          new Array({ ...payload, subtotal: payload.qty * payload.price })
        );
      }

      commit("setCartItems", getItem("cart"));
    },
    changeQty(context, item) {
      const data = getItem("cart");

      setItem(
        "cart",
        data.map((i) => {
          if (i.sign === item.sign) {
            return {
              ...i,
              qty: item.qty,
              subtotal: item.qty * i.price,
            };
          }
          return i;
        })
      );

      context.commit("setCartItems", getItem("cart"));
    },
    deleteFromCart(context, payload) {
      const data = getItem("cart");
      const newData = data.filter((d) => d.title !== payload.title);
      console.log(newData);
      setItem("cart", newData);
      context.commit("setCartItems", newData);
    },
    getRoomNumber({ commit }) {
      commit("setRoomNumber", getItem("number"));
    },
    setRoomNumber({ commit }, payload) {
      setItem("number", payload);
      commit("setRoomNumber", payload);
    },
    clearRoomNumber({ commit }) {
      setItem("number", "");
      commit("setRoomNumber", "");
    },
    submitOrder({ state, commit }, payload) {
      const items = axios.post("/", {
        order: true,
        roomNumber: state.roomNumber,
        paymentType: payload.paymentType,
        deliveryType: payload.deliveryType,
        deliveryTime: payload.deliveryTime,
        comment: payload.comment,
        items: state.cartItems,
      });

      setItem("cart", "");
      commit("setCartItems", "");
    },
    sendTestimonial(context, payload) {
      const items = axios.post("/", {
        testimonial: true,
        question1: payload.question1,
        question2: payload.question2,
        question3: payload.question3,
        question4: payload.question4,
        question5: payload.question5,
        question6: payload.question6,
        question7: payload.question7,
        question8: payload.question8,
        question9: payload.question9,
        question10: payload.question10,
        room: context.state.roomNumber,
        body: payload.body,
      });
    },
  },
});
