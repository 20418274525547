<template>
  <BasePopup>
    <div class="popup__inner">
      <div class="popup__header">
        <div class="popup__title">Ваш язык / Your Language</div>
        <div class="popup__langs">
          <a href="" class="popup__lang" @click.prevent="lang('ru')">
            <img src="https://khantengri.kg/uploads/russia2.svg" />
          </a>
          <a href="" class="popup__lang" @click.prevent="lang('en')">
            <img src="https://khantengri.kg/uploads/united-kingdom2.svg" />
          </a>
        </div>
        <div class="popup__title">{{ $t("title") }}</div>
        <div class="popup__input">
          <input type="number" v-model="number" />
        </div>
        <button class="popup__btn btn-common" @click="setRoomNumber">
          {{ $t("set") }}
        </button>
      </div>
    </div>
  </BasePopup>
</template>

<script>
import i18n from "@/i18n"
import BasePopup from "@/components/popups/BasePopup"

export default {
  name: "RoomPopup",
  components: {
    BasePopup,
  },
  data() {
    return {
      number: 0,
    }
  },
  i18n: {
    messages: {
      ru: {
        title: "Выберите номер вашей комнаты",
        free: "Бесплатно",
        set: "Установить",
      },
      en: {
        title: "Please insert your room number",
        free: "Free",
        set: "Save",
      },
    },
  },
  methods: {
    setRoomNumber() {
      if (this.number && this.number > 0) {
        this.$store.dispatch("setRoomNumber", this.number)
      }
    },
    lang(value) {
      this.$store.dispatch("setLanguage", value)
      this.$nextTick(() => {
        i18n.locale = value
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.popup__inner {
  padding: 30px;
}
.room-popup {
  position: fixed;
  z-index: 99;
  background: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 110;
  overflow-y: auto;
  padding: 20px;
  background-color: rgba(11, 11, 11, 0.8);
  padding: 32px;
  background: white;
  border-radius: 16px;
  padding: 32px;

  &__content {
    background: white;
    width: 85vw;
    min-width: 320px;
    max-width: 720px;
    padding: 32px;
    border-radius: 10px;
  }

  &__title {
    font-weight: bold;
  }

  &__input {
    margin-top: 25px;

    input {
      width: 80px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
  }

  &__btn {
    margin-top: 25px;
  }
}

.popup__langs {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  img {
    width: 35px;
  }
}

.popup__lang {
  margin: 0 8px;
}
</style>
