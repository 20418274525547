<template>

  <div class="food-menu">

    <div class="food-menu__item">

      <div class="food-menu__image" v-if="image">

        <ImageUi :imageUrl="image" imageClass="image"></ImageUi>

      </div>

      <div v-if="body" class="food-menu__description"> {{ body }} </div>

      <div class="food-menu__title">{{ title }}</div>

      <div class="food-menu__price">{{ price }}</div>

      <div class="food-menu__qt qty">

        <input
          @click="discrease(index)"
          type="button"
          value="-"
          class="button-minus"
          data-field="quantity"
        />

        <input
          type="number"
          step="1"
          max=""
          disabled
          :value="item.qty"
          name="quantity"
          class="quantity-field"
        />

        <input
          @click="increase(index)"
          type="button"
          value="+"
          class="button-plus"
          data-field="quantity"
        />

      </div>

      <a
        class="food-menu__order"
        href="#"
        @click.prevent="addToCart(item, type)"
      >
         {{ addButtonText }}
      </a>

    </div>

  </div>

</template>

<script>
import { mapState } from "vuex";
import ImageUi from "@/components/ui/Image";
export default {
  props: {
    item: Object,
    index: Number,
    type: String,
  },
  components: {
    ImageUi,
  },
  data() {
    return {
      added: false,
    };
  },
  computed: {
    image() {
      if (this.item.image) {
        return (
          "https://orient.smng.kg/" +
          "uploads/" +
          this.item.image.replace("|", "thumbnail/")
        );
      }
      return false;
    },
  },
  i18n: {
    messages: {
      ru: {
        order: "Заказать",
        added: "Добавлено",
        free: "Бесплатно",
      },
      en: {
        order: "Order",
        added: "Added",
        free: "Free",
      },
    },
  },
  computed: {
    ...mapState(["currentLanguage"]),
    title() {
      return this.currentLanguage === "ru"
        ? this.item.title_ru
        : this.item.title_en || this.item.title_ru;
    },
    body() {
      return this.currentLanguage === "ru"
        ? this.item.body_ru
        : this.item.body_en || this.item.body_ru;
    },
    price() {
      return this.item.price ? this.item.price + " c" : this.$t("free");
    },
    image() {
      if (this.item.image) {
        return (
          "https://orient.smng.kg/" +
          "uploads/" +
          this.item.image.replace("|", "thumbnail/")
        );
      }
      return false;
    },
    addButtonText() {
      return this.added ? this.$t("added") : this.$t("order");
    },
  },
  methods: {
    increase(index) {
      this.$emit("increaseQty", index);
    },
    discrease(index) {
      this.$emit("discreaseQty", index);
    },
    addToCart(item, itemType) {
      this.added = true;
      this.$store.dispatch("updateAddedEffect", true);
      this.$store.dispatch("addToCart", { ...item, itemType });
      setTimeout(() => {
        this.added = false;
        this.$store.dispatch("updateAddedEffect", false);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 150px;
  height: 150px;
}
</style>

