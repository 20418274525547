<template>
  <div class="footer">
    <div class="footer__room-info" v-if="roomNumber">
      <span>{{ $t("yourNumberIs", [roomNumber || "-"]) }}</span>
      <!--<a href="#" @click.prevent="clearRoomNumber()">{{
        $t("changeNumber")
      }}</a>-->
    </div>
    <div class="footer__langs-title">Ваш язык / Your Language</div>
    <div class="footer__langs">
      <a href="" class="footer__lang" @click.prevent="lang('ru')"
        ><img src="https://khantengri.kg/uploads/russia2.svg"
      /></a>
      <a href="" class="footer__lang" @click.prevent="lang('en')"
        ><img src="https://khantengri.kg/uploads/united-kingdom2.svg"
      /></a>
    </div>
    <span class="copyright">{{
      $t("copyright", [new Date().getFullYear()])
    }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/i18n";
export default {
  computed: {
    ...mapState(["roomNumber", 'currentLanguage']),
  },
  methods: {
    clearRoomNumber() {
      this.$store.dispatch("clearRoomNumber");
    },
    lang(value) {
      this.$store.dispatch("setLanguage", value);
      this.$nextTick(() => {
        i18n.locale = value;
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        yourNumberIs: "Ваш номер комнаты №{0}",
        changeNumber: "Сменить номер",
        copyright: "@ {0} Отель «Orient»",
      },
      en: {
        yourNumberIs: "Your room number is #{0}",
        changeNumber: "Change Room Number",
        copyright: '@ {0} "Orient" Hotel',
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #53284f;
  margin-top: 20px;
  padding: 20px;

  a {
    color: white;
    font-size: 14px;
  }

  .copyright {
    font-size: 13px;
    color: white;
  }

  &__langs-title {
    color: white;
    font-size: 13px;
    margin-bottom: 5px;
  }

  &__langs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    a {
      margin: 0 5px;
    }
  }

  &__lang img {
    width: 25px;
  }

  &__room-info {
    margin: 14px 0;
    span {
      display: block;
      color: white;
    }

    a {
      text-decoration: none;
      border-bottom: 1px dashed;
    }
  }
}
</style>
