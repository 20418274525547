import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import Vuelidate from "vuelidate"
import { i18n } from "@/i18n"

import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

export const eventBus = new Vue()
Vue.use(BootstrapVue)

Vue.config.productionTip = false
Vue.use(Vuelidate)
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
