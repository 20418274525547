<template>

  <div class="image">

    <img
      v-show="isImageReady"
      :class="imageClass"
      :src="imageUrl"
      @load="onImageLoaded"
    />

    <b-spinner
      v-if="!isImageReady"
      type="grow"
      variant="dark"
      class="image__spinner"
    ></b-spinner>

  </div>

</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    imageClass: {
      type: String,
      default: "image",
    },
  },
  data() {
    return {
      isImageReady: false,
    };
  },
  methods: {
    onImageLoaded() {
      this.isImageReady = true;
    },
  },
};
</script>

<style style="scss" scoped>
.image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  min-width: 100px;
  margin: auto;
}
</style>

